export class Product {
  constructor() {
    this.id = null;
    this.name = "";
    this.description = "";
    this.category = null;
    this.insurer = null;
    // this.featurePicture = null;
    this.price = "";
    this.documents = [];
    this.dateResigned = "";
    this.createdBy = "";
    this.membershipNumber = "";
    this.dependantCode = "";
    this.beneficiaryType = "";
    this.lateJoinerPenalty = "";
    this.totalContrib = "";
    this.inceptionDate = "";
  }
}
