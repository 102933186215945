<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "New"} Product` }}
            </h4>
            <v-form ref="productForm" @submit.prevent="save()">
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="productModel.name"
                    label="Name"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Name is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="productModel.description"
                    label="Description"
                    required
                    counter="50"
                    outlined
                    :rules="[(v) => !!v || 'Product Description is required']"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <base-insurer-picker
                    :chosen-insurer.sync="productModel.insurer"
                    :initial="productModel.insurer"
                    label="Insurer"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Insurer is required']"
                  />
                </v-col>
                <v-col md="6">
                  <base-product-category-picker
                    :chosen-product-category.sync="productModel.category"
                    :initial="productModel.category"
                    label="Product Category"
                    required
                    outlined
                    :rules="[(v) => !!v || 'Product Category is required']"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    v-model="productModel.price"
                    label="Price"
                    outlined
                    prefix="R "
                    maxlength="4"
                    type="Number"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Product } from "@/models/Product";
import { gql } from "apollo-boost";

export default {
  name: "EditProducts",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      productModel: new Product(),
      me: "",
    };
  },

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            name
            description
            category
            insurer
            price
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit || !this.id;
      },
      variables() {
        return {
          id: this.id,
        };
      },
      result({ data }) {
        this.isLoading = false;
        const product = data.product;
        this.productModel.id = product.id;
        this.productModel.name = product.name;
        this.productModel.description = product.description;
        this.productModel.category = product.category;
        this.productModel.insurer = product.insurer;
        this.productModel.price = product.price;
      },
    },
  },

  created() {
    this.isEdit = !!this.id;
    if (this.isEdit) {
      this.isLoading = true;
    }
  },

  methods: {
    async save() {
      this.isLoading = true;
      if (!this.$refs.productForm.validate()) {
        this.$swal("Error", "Validation failed", "error");
        this.isLoading = false;
        return;
      }

      try {
        if (this.isEdit) {
          await this.updateProduct();
          this.$swal("Success!", "Product was updated", "success");
        } else {
          await this.createProduct();
          this.$swal("Success!", "Product was created", "success");
        }
        this.$emit("product-updated");
      } catch (error) {
        this.$swal(
          "Error!",
          "Something went wrong. Please check the data and try again.",
          "error"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async updateProduct() {
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation update($product: ProductUpdateInput!) {
            updateProduct(product: $product) {
              id
              updated
            }
          }
        `,
        variables: {
          product: {
            id: this.productModel.id,
            name: this.productModel.name,
            description: this.productModel.description,
            category: this.productModel.category,
            insurer: this.productModel.insurer,
            price: parseFloat(this.productModel.price),
          },
        },
      });
      if (!response || (response.errors && response.errors.length > 0)) {
        throw new Error(
          response?.errors?.[0]?.message || "Failed to update product."
        );
      }
    },

    async createProduct() {
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation createProduct($product: ProductCreateInput!) {
            createProduct(product: $product) {
              id
            }
          }
        `,
        variables: {
          product: {
            name: this.productModel.name,
            description: this.productModel.description,
            category: this.productModel.category,
            insurer: this.productModel.insurer,
            price: parseFloat(this.productModel.price),
            createdBy: this.me.id,
          },
        },
      });
      if (!response || (response.errors && response.errors.length > 0)) {
        throw new Error(
          response?.errors?.[0]?.message || "Failed to create product."
        );
      }
      this.productModel.id = response.data.createProduct.id;
    },
  },
};
</script>

<style lang="scss" scoped></style>
